import { json, LinksFunction, LoaderFunction } from '@remix-run/node';
import { Link, Outlet, useLoaderData } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientOnly } from 'remix-utils/client-only';
import styles from '@assets/css/client-common.css?url';
import blog from '@assets/images/blog_icon.png';
import insta from '@assets/images/insta_icon.png';
import kakao from '@assets/images/kakao_icon.png';
import logo02 from '@assets/images/logo.png';
import tstory from '@assets/images/tstory_icon.png';
import MainLoadingSpinner from '@components/MainLoadingSpinner';
import { useCustomFetcherSubmit } from '@hooks/useCustomFetcherSubmit';
import { getClientUserEmail, isClientAuthenticate } from '@server/login/login.client.server';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader: LoaderFunction = async ({ request }) => {
    const isLogin = await isClientAuthenticate(request);
    const email = await getClientUserEmail(request);
    console.log(email);

    return json({
        isLogin,
        email,
    });
};

export default function ClientLayout() {
    return (
        <ClientOnly>
            {() => (
                <>
                    <Header />
                    <Outlet />
                    <Footer />
                    <MainLoadingSpinner />
                </>
            )}
        </ClientOnly>
    );
}

function Header() {
    const data = useLoaderData<typeof loader>() as {
        isLogin: boolean;
        email: string;
    };
    const { t, i18n } = useTranslation();
    const { submit, submitOptions } = useCustomFetcherSubmit();
    const [moIcon, setMoIcon] = useState(false);

    return (
        <header className={`mainHead ${moIcon ? 'moAct' : ''}`}>
            <div className="headerBox headWh">
                <div className="headerInner">
                    <Link to="/" className="headerLogoBox">
                        <img src={logo02} alt="" />
                    </Link>

                    <div className="moIconBox">
                        <div
                            className="moIcon"
                            onClick={(e) => {
                                setMoIcon(!moIcon);
                            }}
                            role="presentation"
                        >
                            <span className="moLine"></span>
                            <span className="moLine"></span>
                            <span className="moLine"></span>
                        </div>
                    </div>

                    <div
                        className={`headerConBox ${moIcon ? 'show slide' : ''}`}
                        style={{
                            display: moIcon ? 'block' : 'none',
                        }}
                    >
                        <div className="headerConInner">
                            <div className="headerMenuList">
                                <div className="headerMenuUnit">
                                    <Link to="/company" className="headerMenu" reloadDocument={true}>
                                        {t('header.company')}
                                    </Link>
                                </div>
                                <div className="headerMenuUnit">
                                    <Link to="/technology" className="headerMenu" reloadDocument={true}>
                                        {t('header.technology')}
                                    </Link>
                                </div>
                                <div className="headerMenuUnit">
                                    <Link to="/product" className="headerMenu" reloadDocument={true}>
                                        {t('header.product')}
                                    </Link>
                                </div>

                                <div className="headerMenuUnit">
                                    <Link to="/media" className="headerMenu" reloadDocument={true}>
                                        {t('header.media')}
                                    </Link>
                                </div>
                                <div className="headerMenuUnit">
                                    <div className="header_dept">
                                        <Link to="#" className="headerMenu demoMenu">
                                            Demo Play
                                        </Link>
                                        <div className="dept_list">
                                            <div className="in">
                                                <Link
                                                    to="/voice/automatic-transcribing"
                                                    className="dep_copy"
                                                    reloadDocument={true}
                                                >
                                                    {t('header.automatedTranscribing')}
                                                </Link>
                                                <Link
                                                    to="/voice/voice-conversion"
                                                    className="dep_copy"
                                                    reloadDocument={true}
                                                >
                                                    {t('header.voiceConversion')}
                                                </Link>
                                                <Link
                                                    to="/voice/text-to-speech"
                                                    className="dep_copy"
                                                    reloadDocument={true}
                                                >
                                                    {t('header.textToSpeech')}
                                                </Link>
                                                <Link
                                                    to="/voice/automatic-dubbing"
                                                    className="dep_copy"
                                                    reloadDocument={true}
                                                >
                                                    {t('header.automatedDubbing')}
                                                </Link>
                                                <Link
                                                    to="/voice/automatic-dubbing-premium"
                                                    className="dep_copy"
                                                    reloadDocument={true}
                                                >
                                                    {t('header.automatedDubbingPremium')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    id="homeLang"
                                    className="lang"
                                    onClick={async (e) => {
                                        e.preventDefault();

                                        if (i18n.language === 'en') {
                                            i18n.changeLanguage('ko');
                                            await submit(null, {
                                                ...submitOptions,
                                                action: '/api/change-language/ko',
                                            });
                                        } else if (i18n.language === 'ko') {
                                            i18n.changeLanguage('en');
                                            await submit(null, {
                                                ...submitOptions,
                                                action: '/api/change-language/en',
                                            });
                                        }
                                    }}
                                >
                                    {i18n.language === 'en' ? 'KOR' : 'ENG'}
                                </button>

                                {!moIcon && data.isLogin && (
                                    <div className="user_menu_list">
                                        <div className="user_menu_con">
                                            <Link to="/logout" className="user_menu devLogin">
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {data.email && data.email === 'test@test.test' && (
                                    <div className="headerMenuUnit">
                                        <div className="header_dept">
                                            <Link to="/voice2" className="headerMenu demoMenu">
                                                Demo Play2
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="header_close_area"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

function Footer() {
    const { isLogin } = useLoaderData<typeof loader>() as {
        isLogin: boolean;
    };

    return (
        <>
            {/*
            <div className="scroll_box">
                <span className="scroll up">
                    <img className="comImg" src={scroll_up} alt="" />
                </span>
                <span className="scroll down">
                    <img className="comImg" src={scroll_down} alt="" />
                </span>
            </div>
            */}

            <div className="link_box">
                <Link className="link_icon" to="https://pf.kakao.com/_LjhnG" target="_blank" rel="noreferrer">
                    <img className="com_img" src={kakao} alt="" />
                </Link>
                <Link
                    className="link_icon"
                    to="https://www.instagram.com/aunionai.official/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img className="com_img" src={insta} alt="" />
                </Link>
                <Link className="link_icon" to="https://aunionai.tistory.com/" target="_blank" rel="noreferrer">
                    <img className="com_img" src={tstory} alt="" />
                </Link>
                <Link className="link_icon" to="https://blog.naver.com/aunionai" target="_blank" rel="noreferrer">
                    <img className="com_img" src={blog} alt="" />
                </Link>
            </div>

            <footer>
                <div className="footerInner">
                    <p className="copy_right">Copyright 2023 aunionAI Corp. All Rights Reserved.</p>
                    <ul className="footerMenuList">
                        <li className="footerMenuLi">
                            <Link to="/technology" className="footerMenu">
                                Technology
                            </Link>
                        </li>
                        <li className="footerMenuLi">
                            <Link to="/product" className="footerMenu">
                                Product
                            </Link>
                        </li>
                        <li className="footerMenuLi">
                            <Link to="/company" className="footerMenu">
                                Company
                            </Link>
                        </li>
                        <li className="footerMenuLi">
                            <Link to="/media" className="footerMenu">
                                Media
                            </Link>
                        </li>
                        <li className="footerMenuLi">
                            <Link to="/voice/automatic-transcribing" className="footerMenu">
                                Demo play
                            </Link>
                        </li>
                    </ul>

                    <div className="footerCon">
                        <div className="footerConList">
                            <div className="footerConUnit">
                                <p className="copy_right mo_copy_right">
                                    Copyright 2023 aunionAI Corp. All Rights Reserved.
                                </p>
                                <b className="footerCopy">
                                    COMPANY : AunionAI Co. Ltd. (Copyright 2023 aunionAI Corp. All Rights Reserved.)
                                    <br />
                                    ADDRESS : #C-218, EECS Building, Gwangju Institute of Science and Technology (GIST),
                                    123 Cheomdangwagi-ro, Bug-gu, Gwangju 61005, Republic of Korea
                                    <br />
                                    Email :{' '}
                                    <Link to="mailto:customer_service@aunionai.com">customer_service@aunionai.com</Link>
                                    <br />
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="mo_footer">
                <div className="in">
                    {!isLogin ? (
                        <Link to="/login" className="copy">
                            LOGIN
                        </Link>
                    ) : (
                        <Link to="/logout" className="copy" reloadDocument>
                            LOGOUT
                        </Link>
                    )}

                    {!isLogin && (
                        <Link to="/signup" className="copy">
                            SIGN UP
                        </Link>
                    )}

                    {/*
                    <Link to="#" className="copy">
                        PC VER
                    </Link>
                    */}
                </div>
            </div>
        </>
    );
}
